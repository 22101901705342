import * as React from "react"
import { Link } from "gatsby"
import {
  Container,
  Image,
  Menu,
} from 'semantic-ui-react'


export default function HeaderBar() { return (
  <Menu fixed='top' style={{overflowX: 'auto', overflowY: 'visible'}}>
      <Container>
        <Menu.Item as='a' href='/' header>
          <Image size='mini' src='/images/DrPlantabyte-logo3-64px-circle.png' style={{ marginRight: '1.5em' }} />
          Dr. Christopher C. Hall | plantabyte.net
        </Menu.Item>
        
        <Menu.Item>
          <Link to="/about">About Me</Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/blog">Blog</Link>
        </Menu.Item>
        
        <Menu.Item>
          <Link to="/products">Projects</Link>
        </Menu.Item>
        
        <Menu.Item>
          <Link to="/media">Publications & Media</Link>
        </Menu.Item>
        
        <Menu.Item style={{textAlign:'center'}}>
          <a href="https://www.linkedin.com/in/christopher-hall-b00b44110/"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" ><title>LinkedIn</title><g><path d="M34,2.5v29A2.5,2.5,0,0,1,31.5,34H2.5A2.5,2.5,0,0,1,0,31.5V2.5A2.5,2.5,0,0,1,2.5,0h29A2.5,2.5,0,0,1,34,2.5ZM10,13H5V29h5Zm.45-5.5A2.88,2.88,0,0,0,7.59,4.6H7.5a2.9,2.9,0,0,0,0,5.8h0a2.88,2.88,0,0,0,2.95-2.81ZM29,19.28c0-4.81-3.06-6.68-6.1-6.68a5.7,5.7,0,0,0-5.06,2.58H17.7V13H13V29h5V20.49a3.32,3.32,0,0,1,3-3.58h.19c1.59,0,2.77,1,2.77,3.52V29h5Z" fill="currentColor"></path></g></svg><br/>LinkedIn</a>
        </Menu.Item>
        
        <Menu.Item style={{textAlign:'center'}}>
          <a href="https://github.com/DrPlantabyte/"><svg height="32" aria-hidden="true" viewBox="0 0 16 16" version="1.1" width="32" data-view-component="true" ><title>GitHub</title><path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg><br/>GitHub</a>
        </Menu.Item>
        
        <Menu.Item style={{textAlign:'center'}}>
          <a href="https://twitter.com/DrCHall"><svg viewBox="0 0 24 24" aria-hidden="true" width="34" height="34" ><title>Twitter</title><g><path fill="rgb(29, 155, 240)" d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg><br/>Twitter</a>
        </Menu.Item>
        
        <Menu.Item style={{textAlign:'center'}}>
          <a href="mailto:cchall@zoho.com"><svg width="32" height="32" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title>E-Mail</title><rect x="3.4396" y="7.6729" width="24.342" height="15.875" ry="7.7703e-7" fill="#888" stroke-linecap="round" stroke-width="1.0584"/><path fill="#fff" d="m4.0735 24.963c-0.9565-0.35682-1.6926-1.0553-1.9428-1.9488-0.0048867-4.8411-0.0097733-9.6821-0.01466-14.523 0.21064-0.86542 0.67523-1.6568 1.4609-1.9631 8.4837-0.33243 11.781-0.1641 24.049-0.1641 1.0498 0.40189 1.7452 1.1406 2.0076 2.1208-3e-6 4.7964-5e-6 9.5928-7e-6 14.389-0.2695 1.1017-1.0026 1.9378-2.0307 2.0901-0.26919 0.03651-23.279 0.03562-23.529-9.1e-4zm21.849-1.987c-0.02318-0.05973-6.6001-5.8757-6.6301-5.863-0.01857 0.0079-0.28647 0.2371-0.59533 0.50943-0.77344 0.68196-1.1316 0.91434-1.6904 1.0968-0.81696 0.26668-1.9423 0.18535-2.7179-0.19644-0.37088-0.18258-0.53851-0.30746-1.2602-0.9388-0.30435-0.26625-0.56435-0.48007-0.57778-0.47515-2.3101 2.0319-4.115 3.6482-6.5605 5.8182l-0.081212 0.0714c6.703-0.07068 13.411 0.10143 20.113-0.02234zm-14.967-7.1923c0.0078-0.01955-6.7721-5.9804-6.8022-5.9804-0.02262 4.2753-0.014911 7.5336-0.014911 12.14l0.063176-0.05758c2.363-2.1646 4.2702-3.7918 6.7539-6.1016zm16.663-5.98c-0.02598 4.21e-4 -6.8019 5.9436-6.8062 5.9697-0.0041 0.02476 6.7714 6.1316 6.803 6.1316-0.04497-4.2004 0.12637-7.3995 0.0032-12.101zm-11.29 7.0421c3.1255-2.3319 6.0128-5.125 8.4612-7.2948 0.60228-0.53375 1.155-1.0201 1.2282-1.0809 0.07325-0.06072 0.12315-0.12045 0.11087-0.13273-7.2271-0.028875-12.735-0.013552-20.517-0.00816 3.7467 2.801 6.0824 6.0705 9.6702 8.4317 0.36709 0.23541 0.67703 0.26056 1.046 0.08489z" stroke-width=".028078"/></svg><br/>eMail</a>
        </Menu.Item>

      </Container>
    </Menu>
) }

