import * as React from "react"
import {
  Container,
  Image,
  Segment,
} from 'semantic-ui-react'


export default function FooterBar() { return (
  <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign='center'>
        <Image centered size='mini' src='/images/DrPlantabyte-logo3-64px-circle.png' />
        <p>This website was created by Dr. Christopher C. Hall, Copyright © 2022</p>
      </Container>
    </Segment>
) }

/* To add stuff to the footer bar, follow this format (insert right under </p> in <Container>):
        <List horizontal inverted divided link size='small'>
          <List.Item as='a' href='#'>
            Site Map
          </List.Item>
          <List.Item as='a' href='#'>
            Contact Us
          </List.Item>
          <List.Item as='a' href='#'>
            Terms and Conditions
          </List.Item>
          <List.Item as='a' href='#'>
            Privacy Policy
          </List.Item>
        </List> */

